import React, { useState } from "react"
import styled from "styled-components"
import logo from "../images/favicon.png"
import { Link } from "gatsby"
import { device } from "../utils/devices"
import { isMobile } from "react-device-detect"

const Head = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  align-items: center;
  font-size: 12px;
  width: 100vw;
  box-sizing: border-box;
  color: white;
  margin-bottom: 1rem;
`

const Logo = styled.div`
  width: 32px;

  img {
    width: 100%;
  }
`

const Menu = styled.nav`
  position: fixed;
  top: 0;
  background: white;
  height: 100vh;
  width: 100vw;
  transition: all 0.2s ease-in-out;
  right: -100vw;
  transform: ${props => (props.open ? `translateX(-100vw)` : `translateX(0)`)};

  @media ${device.laptop} {
    position: static;
    transform: none;
    background: transparent;
    width: auto;
    height: auto;
  }

  ul {
    padding: 0;
    display: flex;
    flex-direction: column;
    list-style: none;
    align-items: center;
    margin: 0;
    justify-content: center;
    height: 100vh;

    @media ${device.laptop} {
      flex-direction: row;
      height: auto;
    }

    li {
      font-size: 30px;
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 2rem;

      @media ${device.laptop} {
        font-size: 16px;
        margin: 0 2rem 0 0;
      }

      a {
        color: black;
        text-decoration: none;

        @media ${device.laptop} {
          color: white;
        }

        &:hover {
          opacity: 0.8;
        }
      }

      &:last-of-type {
        margin: 0;
      }
    }
  }
`

const Button = styled.button`
  background: #ed2123;
  color: white;
  border: 0;
  appearance: none;
  border-radius: 6px;
  letter-spacing: 0.2em;
  font-weight: bold;
  padding: 10px 16px;
  min-width: 100px;
  outline: none;
  transition: 0.2s all ease-in-out;
  cursor: pointer;

  &:hover {
    transform: translate(0, -2px);
  }
`

const MenuButton = styled.div`
  position: relative;
  z-index: 999999;
  text-transform: uppercase;
  font-weight: bold;
  color: ${props => (props.open ? `black` : `white`)};
`

const Header = () => {
  const [menuOpen, setMenu] = useState(false)

  return (
    <>
      <Head>
        <Link to="/">
          <Logo>
            <img src={logo} />
          </Logo>
        </Link>
        {isMobile && (
          <MenuButton open={menuOpen} onClick={() => setMenu(!menuOpen)}>
            {menuOpen ? `Close` : `Menu`}
          </MenuButton>
        )}
        <Menu open={menuOpen}>
          <ul onClick={() => setMenu(!menuOpen)}>
            <li>
              <Link to="/rules">Rules</Link>
            </li>
            <li>
              <a href="mailto:jason@trapkaraoke.com">Contact</a>
            </li>
            <li>
              <Link to="/how-to-play">How to Play</Link>
            </li>
          </ul>
        </Menu>
      </Head>
    </>
  )
}

export default Header
