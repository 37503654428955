/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import { styles } from "./layout.css"
import styled from "styled-components"

const Wrapper = styled.div`
  min-height: calc(100vh - 120px);
  background: #30a4d5;

  footer {
    margin-top: 2rem;
    color: white;
    padding: 5vw 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    a {
      color: white;
      text-decoration: none;
    }
  }

  main {
    width: 100vw;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 120px);
  }
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Wrapper style={styles}>
      <Header />
      <main>{children}</main>
      <footer>
        <div>
          <a href="https://trapkaraoke.com/privacy-policy">Privacy Policy</a>
        </div>
      </footer>
    </Wrapper>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
